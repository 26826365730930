import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import SocialLinks from '../common/SocialLinks';
import Logo from '../../public/img/logo-new.svg';
import RedirectIcon from '../../public/img/icons/icon_redirect.svg';
import style from './style.module.scss';
import WindowsDownloadIcon from '../../public/img/icons/icon_windows.svg';
import React from 'react';
import { TERMINAL_MAC_OS_LINKS } from '@/core/constants';
import { useRouter } from 'next/router';

const PAGES = [
  { href: '/', name: 'link_home', internal: true },
  { href: '/terminal/overview', name: 'link_overview', internal: true },
  { href: '/terminal/how-to-setup', name: 'link_howToSetup', internal: true },
  { href: '/terminal/prices', name: 'link_prices', internal: true },
  { href: '/terminal/download', name: 'link_download', internal: true },
  // { href: 'google.com', name: 'link_forums', internal: false },
  { href: '/terminal/contacts', name: 'link_contacts', internal: true },
];

const Footer = () => {
  const { t, i18n } = useTranslation();
  const { language: lng } = i18n;
  const { locale } = useRouter();

  const TIGER_TRADE_LINKS = [
    { name: 'footer_contacts_button2', href: 'https://www.tiger.trade/' },
    { name: 'footer_contacts_button1', href: '/terminal/contacts' },
  ];

  const MORE_PRODUCTS_LINKS = [
    {
      name: 'header_products_mac_terminal_redirect_button',
      target: '_blank',
      href: TERMINAL_MAC_OS_LINKS[locale],
    },
    {
      name: 'header_products_broker_redirect_button',
      target: '_blank',
      href: `https://broker.tiger.trade/${lng === 'ru' ? 'ru' : ''}`,
    },
    /*
    {
      name: 'header_in_products_copytrading_redirect_button',
      target: '_blank',
      href: 'https://copy.tiger.trade/',
    },
    */
  ];

  const TRADING_PLATFORM_LINKS = [
    { href: '/', name: 'link_home', internal: true },
    { href: '/terminal/overview', name: 'link_overview' },
    { href: '/terminal/how-to-setup', name: 'link_howToSetup' },
    { href: '/terminal/prices', name: 'link_prices' },
    {
      href: `${t('support_url')}`,
      name: 'link_support',
      target: '_blank',
    },
    {
      href: `${t('affilates_url')}`,
      name: 'link_affilates',
      target: '_blank',
    },
    {
      href: `${t('footer_links_feedback_href')}`,
      name: 'footer_links_feedback_title',
      target: '_blank',
    },
  ];
  const USEFUL_LINKS = [
    /*
    { href: 'google.com', name: 'footer_pages_purchase', internal: false },
    { href: 'google.com', name: 'footer_pages_documentation', internal: false },
    { href: '/overview', name: 'link_overview', internal: true },
     */
    // { href: '/using-cookies', name: 'link_usingCookies', internal: true },
    {
      href: `${t('support_url')}`,
      name: 'link_support',
      internal: true,
      icon: <RedirectIcon className="foot-redirect-icon" />,
      className: 'link-with-icon',
      target: '_blank',
    },
    {
      href: `${t('affilates_url')}`,
      name: 'link_affilates',
      internal: true,
      icon: <RedirectIcon className="foot-redirect-icon" />,
      className: 'link-with-icon',
      target: '_blank',
    },
    { href: '/terminal/privacy-policy', name: 'link_privacyPolicy', internal: true },
    { href: '/terminal/terms-of-service', name: 'link_termsAndRules', internal: true },
    // { href: '/public-offer', name: 'link_publicOffer_short', internal: true },
    {
      href: '/terminal/end-user-license-agreement',
      name: 'link_endUserLicenseAgreement',
      internal: true,
    },
    {
      href: `${t('footer_links_feedback_href')}`,
      name: 'footer_links_feedback_title',
      internal: false,
      icon: <RedirectIcon className="foot-redirect-icon" />,
      className: 'link-with-icon',
      target: '_blank',
    },
  ];

  return (
    <footer className={style.footer}>
      <div className={style.topPart}>
        <div className={style.logo}>
          <Logo />
          <span className={style.logoTitle}>Tiger.Trade Windows</span>
        </div>
        <div className={style.downloadAndSupport}>
          <div className={style.support}>
            <p className={style.supportTitle}>{t('footer_email')}</p>
            <a href={`mailto:${t('footer_email_text')}`}>{t('footer_email_text')}</a>
          </div>
          <Link href={'/terminal/download'}>
            <div className={style.downloadLink}>
              <WindowsDownloadIcon />
              <div className={style.downloadTextWrapper}>
                <span className={style.downloadTitle}>{t('link_download')}</span>
                <span className={style.downloadSubtitle}>{t('for_windows')}</span>
              </div>
            </div>
          </Link>
        </div>
      </div>
      <div className={style.middlePart}>
        <div className={style.linksWrapper}>
          <p className={style.linksTitle}>{t('footer_wnd_tp_button')}</p>
          <div className={style.links}>
            {TRADING_PLATFORM_LINKS.map(({ href, target, name }) => (
              <Link key={name} href={href} target={target || '_self'}>
                <span className={style.link}>{t(name)}</span>
              </Link>
            ))}
          </div>
        </div>
        <div className={style.linksWrapper}>
          <p className={style.linksTitle}>{t('footer_section2')}</p>
          <div className={style.links}>
            {MORE_PRODUCTS_LINKS.map(({ href, target, name }) => (
              <Link key={name} href={href} target={target || '_self'}>
                <span className={style.link}>{t(name)}</span>
              </Link>
            ))}
          </div>
        </div>
        <div className={style.linksWrapper}>
          <p className={style.linksTitle}>{t('footer_section1')}</p>
          <div className={style.links}>
            {TIGER_TRADE_LINKS.map(({ href, name }) => (
              <Link key={name} href={href}>
                <span className={style.link}>{t(name)}</span>
              </Link>
            ))}
          </div>
        </div>
        <SocialLinks className={style.socials} />
      </div>
      <div className={style.bottomPart}>
        <div className={style.bottomSection1}>
          {t('footer_law_section1')}
          <br />
          {t('footer_law_section2')}
        </div>
        <div className={style.bottomSection2}>
          {t('footer_law_section3')}
          <br />
          {t('footer_law_section4')}
        </div>
        <div className={style.bottomSection3}>
          <Link href={'/terminal/privacy-policy'}>{t('footer_law_privacy_button')}</Link> ·
          <Link href={'/terminal/terms-of-service'}>{t('footer_law_terms_button')}</Link>
          <br />
          <Link href={'/terminal/end-user-license-agreement'}>
            {t('link_endUserLicenseAgreement')}
          </Link>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
